.no-transform {
	transform: none !important;
}

.rotate-90 {
	transform: rotate(90deg) !important;
}

.rotate-180 {
	transform: rotate(180deg) !important;
}

.rotate-270 {
	transform: rotate(270deg) !important;
}
